<template>
    <v-card flat>
        <v-card-text class="pb-0 mb-0">
            <v-row style="border-bottom: 2px solid #4E87DD;">
                <v-col style="color:#4E87DD; font: normal normal bold 18px/25px Apple SD Gothic Neo;">번호인증</v-col>
                <v-col style="color:#555555; font: normal normal 600 14px/25px Apple SD Gothic Neo;" class="text-right">휴대폰 번호를 인증해 주세요</v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
            <v-row class="mt-5">
                <v-col cols="3" style="font: normal normal normal 16px/25px Apple SD Gothic Neo; padding-top:20px; padding-left: 10px;">휴대폰</v-col>
                <v-col cols="7">
                    <v-simple-table>
                        <tr>
                            <td style="font: normal normal normal 16px/37px Apple SD Gothic Neo; color: #333333; width:50px;">010 -</td>
                            <td>
                                <v-text-field dense outlined hide-details :error="formStatus['user_mdn'].err" :ref="'user_mdn'" placeholder="- 없이 입력" v-model="formData.user_mdn"
                                              @keyup="checkChanged('user_mdn')" @change="checkChanged('user_mdn', true)" tabindex="1">
                                    <template v-slot:append v-if="formStatus['user_mdn'].err_msg">
                                        <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                            {{ formStatus['user_mdn'].err_msg }}
                                        </div>
                                    </template>
                                </v-text-field>
                            </td>
                        </tr>
                        <tr v-if="formData.sms_verified_yn != 'Y' && formData.sms_sended_yn == 'Y'">
                            <td style="width:50px;"></td>
                            <td style="padding-top:10px;">
                                <v-text-field dense outlined hide-details :error="formStatus['sms_value'].err" :ref="'sms_value'" placeholder="인증번호를 입력해 주세요"
                                              v-model="formData.sms_value" @keyup="checkChanged('sms_value')" @change="checkChanged('sms_value', true)" tabindex="3">
                                    <template v-slot:append v-if="formStatus['sms_value'].err_msg">
                                        <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                            {{ formStatus['sms_value'].err_msg }}
                                        </div>
                                    </template>
                                </v-text-field>
                            </td>
                        </tr>
                    </v-simple-table>
                </v-col>
                <v-col cols="2">
                    <v-simple-table>
                        <tr>
                            <td>
                                <v-btn color="#4E87DD" width="94px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo; margin-top: 2px;" @click="checkAvailable(retrySendSms)" v-if="formData.sms_sended_yn == 'Y' && formData.sms_verified_yn == 'N'" tabindex="2">재요청</v-btn>
                                <v-btn color="#4E87DD" width="94px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo; margin-top: 2px;" @click="checkAvailable(sendSms)" v-else tabindex="2">인증받기</v-btn>
                            </td>
                        </tr>
                        <tr v-if="smsSended && !smsVerified">
                            <td style="padding-top:13px;">
                                <v-btn color="#4E87DD" width="94px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo; margin-top: 2px;" v-if="formData.sms_value && smsSended && !smsVerified" @click="verifySms">확인</v-btn>
                                <v-btn color="#CCCCCC" width="94px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo; margin-top: 2px;" v-else>확인</v-btn>
                            </td>
                        </tr>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import joinApi from '@/api/join'

export default {
    name: 'join_step_01',
    components: {},
    data: () => ({
        formData: {
            join_tid: '',
            invited_yn: '',
            user_mdn: '',
            sms_value: '',
            sms_sended_yn: 'N',
            sms_verified_yn: 'N',
        },
        formStatus: {
            'user_mdn': {
                err: false,
                err_msg: '',
                sms_sended_val: '',
                verified_val: '',
            },
            'sms_value': {
                err: false,
                err_msg: '',
            }
        }
    }),
    computed: {
        smsSended() {
            return (this.formData && this.formData.sms_sended_yn == 'Y')
        },
        smsVerified() {
            return (this.formData && this.formData.sms_verified_yn == 'Y')
        }
    },
    created() {
    },
    mounted() {
        this.$store.dispatch('join/init', {})
        this.$store.commit('join/setJoinStep', 1)
    },
    methods: {
        doNext() {
            if (this.checkFormErr()) {
                return
            }

            joinApi.submitSmsVerify(this.formData, (va) => {
                va.$store.dispatch('openAlert', {
                    'message': '인증 되었습니다',
                    'va': va,
                    'closeCb': (va) => {
                        va.formData.user_mdn = `010${va.formData.user_mdn}`
                        va.$store.commit('join/setJoinFormStep01', va.formData)
                        va.$router.push(`/join/step02/${va.formData.join_tid}`)
                    }
                })
            }, (err, va) => {
                if (err == 'WAITING_APPROVAL') {
                    va.$router.push('/join/wait_approval')
                } else {
                    va.handleErrorSendSms(err)
                }

            }, this)
        },
        checkSmsNumber(userMdn) {
            let isValid = true
            let regType = /^[0-9+]{7,8}$/;

            if (userMdn == '') {
                this.updateErrStatus('user_mdn', true, '휴대폰 번호를 입력해 주세요')
                isValid = false
            } else if (!regType.test(userMdn)) {
                this.updateErrStatus('user_mdn', true, '잘못된 휴대폰 번호 형식 입니다')
                isValid = false
            }

            return isValid
        },
        handleSuccessSendSms() {
            this.$store.dispatch('openAlert', {'message': '인증번호가 전송되었습니다.'})
            this.$refs.sms_value.focus()
        },
        handleErrorSendSms(err) {
            // NO_USER=사용자 정보 없음
            // NOT_ADMIN=관리자 아님
            // NO_COMPANY=업체정보 없음
            // INVALID_LV_TYPE=업체 레벨 오류(이미PRO 혹은 PTN)
            // CREATE_FAIL=정보생성 오류
            // SEND_FAIL=발송 실패
            let msg = '인증 문자 발송을 실패 했습니다'
            let subMsg = ''

            if (err == 'ALREADY_ADMIN' || err == 'ALREADY_USER') {
                msg = '이미 회원으로 등록되어 있습니다.'
                subMsg = ''
            } else if (err == 'NO_COMPANY') {
                msg = '업체 정보를 찾을 수 없습니다.'
                subMsg = ''
            } else if (err == 'INVALID_LV_TYPE') {
                msg = 'Call Pro 서비스를 사용 중인 업체가 아닙니다.'
                subMsg = ''
            }

            this.$store.dispatch('openAlert', {
                message: msg,
                sub_message: subMsg,
            })
        },
        checkAvailable(callbackFnc) {
            let userMdn = this.formData.user_mdn
            if (this.checkSmsNumber(userMdn)) {
                callbackFnc(userMdn)
            }
        },
        retrySendSms(userMdn) {
            let ctx = this
            joinApi.retrySendSms({
                join_tid: ctx.formData.join_tid,
                user_mdn: userMdn
            }, (va) => {
                va.formStatus.user_mdn.sms_sended_val = userMdn
                va.formStatus.user_mdn.verified_val = ''
                va.formData.sms_value = ''

                va.$nextTick(() => {
                    va.handleSuccessSendSms()
                })

            }, (err, va) => {
                va.handleErrorSendSms(err)
            }, this)
        },
        sendSms(userMdn) {
            joinApi.sendSms({
                user_mdn: userMdn

            }, (joinTid, va) => {
                va.formData.sms_sended_yn = 'Y'
                va.formData.sms_verified_yn = 'N'
                va.formStatus.user_mdn.sms_sended_val = userMdn
                va.formStatus.user_mdn.verified_val = ''
                va.formData.sms_value = ''
                va.formData.join_tid = joinTid

                va.$nextTick(() => {
                    va.handleSuccessSendSms()
                })

            }, (err, va) => {
                va.handleErrorSendSms(err)
            }, this)
        },
        verifySms() {
            if (this.formData.sms_value == '') {
                this.updateErrStatus('sms_value', true, 'SMS를 통해 수신한 인증번호를 입력해 주세요')
                return
            }

            let ctx = this

            joinApi.verifySms({
                join_tid: ctx.formData.join_tid,
                sms_value: ctx.formData.sms_value,

            }, (verifyResult, invitedYn, va) => {
                if (verifyResult == 'SUCCESS' || verifyResult == 'ALREADY_VERIFIED') {
                    va.formData.invited_yn = invitedYn
                    va.formData.sms_verified_yn = 'Y'
                    va.formStatus.user_mdn.verified_val = va.formData.sms_value
                    va.updateErrStatus('user_mdn', false, '')
                    va.doNext()
                } else {
                    let msg = '인증을 실패 했습니다'
                    let subMsg = ''
                    // NO_TRAN, ALREADY_VERIFIED, INCORRECT
                    if (verifyResult == 'NO_TRAN') {
                        msg = '인증시간이 만료되었습니다. 다시 인증해주세요.'
                    } else if (verifyResult == 'INCORRECT') {
                        subMsg = '인증번호가 일치하지 않습니다'
                    } else if (verifyResult == 'ALREADY_VERIFIED') {
                        subMsg = '인증 정보가 잘못되었습니다'
                    }

                    va.$store.dispatch('openAlert', {
                        'message': msg,
                        'sub_message': subMsg,
                        'va': va,
                        closeCb: function (va) {
                            va.formStatus.user_mdn.verified = false
                            va.formStatus.user_mdn.verified_val = ''
                        }
                    })
                }
            }, (err, va) => {
                va.$store.dispatch('openAlert', {
                    'message': '인증을 실패 했습니다.',
                    'va': va,
                    closeCb: function (va) {
                        va.formStatus.user_mdn.verified = false
                        va.formStatus.user_mdn.verified_val = ''
                    }
                })
            }, this)
        },
        checkChanged(t, focusOutFlag) {
            let val = this.formData[t]
            let st = this.formStatus[t]
            let mdnRegType = /^[0-9+]{7,8}$/;

            if (t == 'user_mdn') {
                if (st) {
                    if (focusOutFlag || st.err) {
                        if (!val) {
                            this.updateErrStatus('user_mdn', true, '휴대폰 번호를 입력해 주세요')
                            return
                        } else if (!mdnRegType.test(val)) {
                            this.updateErrStatus('user_mdn', true, '잘못된 휴대폰 번호 형식 입니다')
                            return
                        }
                    }
                    if (st.err) {
                        st.err = false
                        st.err_msg = ''
                    } else if (val != '' && st.sms_sended_val != val) {
                        this.formData.sms_sended_yn = 'N'
                        this.formData.sms_verified_yn = 'N'
                        st.sms_sended_val = ''
                        st.verified_val = ''
                    }
                }
            } else {
                if (st) {
                    if (st.err) {
                        st.err = false
                        st.err_msg = ''
                    }
                }
            }
        },
        checkFormErr() {
            for (let k in this.formStatus) {
                if (this.formStatus[k].err) {
                    return true
                }
            }

            return false
        },
        updateErrStatus(target, err, errMsg) {
            let status = this.formStatus[target]
            let ref = null

            if (target == 'user_id') {
                ref = this.$refs.user_id
            }

            if (!errMsg) {
                errMsg = ''
            }

            if (status) {
                status.err = err
                status.err_msg = errMsg
                if (err && ref) {
                    ref.focus()
                }
            }
        }
    }
}
</script>
<style>
</style>


